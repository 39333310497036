<template>
  <div>
    <a-tabs v-model="searchForm.category_id" type="card" @change="tabChange">
      <a-tab-pane :key="0" tab="平台系统"></a-tab-pane>
      <a-tab-pane :key="1" tab="后台系统"></a-tab-pane>
    </a-tabs>
    <a-card>
      <template slot="title">
        <span class="table-title"
          >{{ searchForm.category_id == 1 ? "后台" : "平台" }}角色列表</span
        >
        <a-button type="primary" icon="plus" @click="showModal('add')"
          >新增</a-button
        >
      </template>
      <a-table
        :loading="loading"
        rowKey="id"
        :columns="columns"
        :data-source="datalist"
        :pagination="pagination"
        @change="onPageChange"
      >
        <!-- 状态 -->
        <template slot="status" slot-scope="text, record">
          <a-switch
            :checked="text == 1"
            checkedChildren="启用"
            unCheckedChildren="停用"
            @change="(checked) => switchStatus(checked, record.id)"
          >
          </a-switch>
        </template>
        <!-- 操作 -->
        <template slot="action" slot-scope="record">
          <a-button type="link" @click="showModal('edit', record.id)"
            >编辑</a-button
          >
          <!-- <a-popconfirm 
            title="您确认要删除该角色吗？"
            @confirm="del(record.id)">
            <a-button type="link">删除</a-button>
          </a-popconfirm> -->
        </template>
      </a-table>
    </a-card>
    <!-- 弹框 -->
    <a-modal
      :title="modalType == 'add' ? '新增角色' : '修改权限'"
      :width="700"
      :visible="dialog"
      :confirm-loading="confirmLoading"
      @ok="modalConfirm"
      @cancel="dialog = false"
    >
      <a-spin :spinning="spinning">
        <a-form-model
          ref="modalForm"
          :model="form"
          :rules="rules"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-form-model-item label="角色名称" prop="group_name">
            <a-input v-model="form.group_name" placeholder="请输入角色名" />
          </a-form-model-item>
          <a-form-model-item label="权限" prop="rule" class="rule_validate">
            <a-input v-model="form.rule" v-show="false"></a-input>
          </a-form-model-item>
          <!-- 菜单列表 -->
          <a-table
            v-if="menuList.length != 0"
            size="middle"
            row-key="id"
            :columns="menuColumns"
            :data-source="menuList"
            :expanded-row-keys="expandRows"
            :pagination="false"
            :row-selection="{
              selectedRowKeys: selectedRules, // 所选值
              onSelect: onSelect,
              onChange: onSelectChange,
              onSelectAll: onSelectAll,
            }"
            @expand="expandChange"
          >
            <template slot="function_list" slot-scope="text, record">
              <a-checkbox-group
                v-model="record.checkedRules"
                @change="(checkedValue) => selectFunc(checkedValue, record)"
              >
                <a-checkbox
                  v-for="item in text"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-checkbox>
              </a-checkbox-group>
            </template>
          </a-table>
        </a-form-model>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      searchForm: {
        category_id: 0,
        page: 1,
        list_rows: 30
      },
      columns: [
        { title: '序号', key: 'id', dataIndex: 'id', align: 'center', width: 65, customRender: (text, record, index) => `${(this.pagination.current - 1) * this.pagination.pageSize + index + 1}` },
        { title: '角色名称', key: 'gname', dataIndex: 'gname' },
        { title: '用户数量', key: 'num', dataIndex: 'num', align: 'center' },
        { title: '添加时间', key: 'uptime', dataIndex: 'uptime', align: 'center' },
        { title: '状态', key: 'status', dataIndex: 'status', scopedSlots: { customRender: 'status' }, align: 'center' },
        { title: '操作', key: 'action', scopedSlots: { customRender: 'action' }, align: 'center' }
      ],
      pagination: {
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50'],
        current: 1,
        pageSize: 30,
        total: 0,
        showTotal: (total) => `共 ${total} 条`
      },
      datalist: [],
      // 弹框
      dialog: false,
      confirmLoading: false,
      modalType: 'add',
      expandRows: [],
      spinning: false,
      form: {
        category_id: 0,
        group_name: '',
        rule: ''
      },
      rules: {
        group_name: [
          { required: true, message: '该项为必填项，请填写', trigger: 'blur' }
        ],
        rule: [
          { required: true, message: '该项为必填项，请勾选', trigger: 'change' }
        ]
      },
      menuColumns: [
        { title: '名称', key: 'name', dataIndex: 'name' },
        { title: '功能', key: 'function_list', dataIndex: 'function_list', align: 'center', scopedSlots: { customRender: 'function_list' } }
      ],
      menuList: [],
      selectedRules: [],
      checks: []
    }
  },
  mounted() {
    this.getRoleList()
  },
  methods: {
    tabChange(key) {
      this.form.category_id = key
      this.getRoleList()
    },
    getRoleList() {
      this.loading = true
      this.api.getRoleList(this.searchForm).then(res => {
        this.datalist = res.data.data
        this.pagination.total = res.data.total
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    onPageChange(pagination) {
      this.pagination = pagination
      this.searchForm.page = pagination.current
      this.searchForm.list_rows = pagination.pageSize
      this.getRoleList()
    },
    showModal(type, roleId) {
      this.modalType = type
      this.dialog = true
      this.$nextTick(() => {
        this.selectedRules = []
        this.$refs.modalForm.resetFields()
        this.getMenuList(roleId)
        if (type == 'add') {
          delete this.form.id
        }
      })
    },
    modalConfirm() {
      console.log('123')
      let menuArr = [] // 改为一维数组
      let allRules = []

      this.menuList.forEach(item => {
        // 权限id
        if (item.type == 1) {
          allRules = [...allRules, ...item.checkedRules]
        } else {
          item.children.forEach(child => {
            allRules = [...allRules, ...child.checkedRules]
          })
        }
        // 一维数组
        menuArr.push(item)
        if (item.children) {
          menuArr = menuArr.concat(item.children)
        }
      })

      allRules = [...allRules, ...this.selectedRules]

      // 筛选选中的菜单信息
      let detail = []
      allRules.forEach(ruleId => {
        menuArr.forEach(menu => {
          if (ruleId == menu.id) {
            detail.push({ id: menu.id, checkedRules: menu.checkedRules })
          }
        })
      })
      this.form.rule = allRules.length != 0 ? JSON.stringify(allRules) : ''
      this.$refs.modalForm.validate(valid => {
        if (valid) {
          this.confirmLoading = true
          let param = { ...this.form }
          param.detail = JSON.stringify(detail)
          if (this.modalType == 'add') {
            this.add(param)
          } else {
            this.edit(param)
          }
        } else {
          return false
        }
      })
    },
    getMenuList(roleId) {
      this.api.getMenuList({ category_id: this.form.category_id }).then(res => {
        this.expandRows = res.data.data.map(item => item.id);
        console.log('res', res)
        this.menuList = res.data.data.map(item => {
          let obj = { ...item }
          obj['sub'] = obj.children   // 由于列表数据中的record获取不到children数据，因此用sub获取
          obj['selectedChild'] = []   // 导航菜单用于记录所勾选的子菜单id

          if (obj.type == 0 && obj.children) {
            obj.children.forEach(sub => {
              sub['checkedRules'] = []  // 用于记录页面的所选中的功能id
            })
          } else {
            obj['checkedRules'] = []
          }
          return obj
        })
        if (roleId) {  // 调详情
          this.getInfo(roleId)
        }
      })
    },
    add(param) {
      this.api.addRole(param).then(res => {
        this.$message.success(res.data.msg)
        this.dialog = false
        this.confirmLoading = false
        this.getRoleList()
      }).catch(() => {
        this.confirmLoading = false
      })
    },
    edit(param) {
      this.api.editRole(param).then(res => {
        this.$message.success(res.data.msg)
        this.dialog = false
        this.confirmLoading = false
        this.getRoleList()
      }).catch(() => {
        this.confirmLoading = false
      })
    },
    getInfo(roleId) {
      this.spinning = true
      this.api.getRoleInfo({ id: roleId }).then(res => {
        let { id, rule_ids, gname } = res.data.data

        let detail = res.data.data.detail || '{}'  // 旧数据的detail为null，不可使用es6的解构赋值设置默认值

        this.$set(this.form, 'id', id)
        this.form.group_name = gname
        this.selectedRules = JSON.parse(rule_ids)
        let detail_rules = JSON.parse(detail)

        if (detail != '{}') {
          detail_rules.forEach(rule => {
            // 功能勾选
            this.menuList.forEach(menu => {
              if (menu.type == 0) {
                menu.children.forEach(child => {
                  if (rule.id == child.id) {
                    child.checkedRules = rule.checkedRules
                    menu.selectedChild.push(child.id)
                  }
                })
              } else {
                if (rule.id == menu.id) {
                  menu.checkedRules = rule.checkedRules
                }
              }
            })
            // 删除功能id，以免重复
            if (rule.checkedRules) {
              rule.checkedRules.forEach(sub => {
                let index = this.selectedRules.findIndex(ruleId => ruleId == sub)
                this.selectedRules.splice(index, 1)
              })
            }
          })
        } else {
          // 处理后台旧数据 或 detail数据为null
          this.menuList.forEach(item => {
            if (this.selectedRules.includes(item.id)) {

              if (item.type == 0) {
                item.children.forEach(child => {
                  if (this.selectedRules.includes(child.id)) {
                    item.selectedChild.push(child.id)
                  }

                  // 页面功能勾选
                  child.function_list.forEach(func => {
                    if (this.selectedRules.includes(func.id)) {
                      child.checkedRules.push(func.id)
                      let index = this.selectedRules.findIndex(ruleId => ruleId == func.id)
                      this.selectedRules.splice(index, 1)
                    }
                  })
                })
              } else {
                if (item.function_list && item.function_list.length != 0) {
                  item.function_list.forEach(func => {
                    if (this.selectedRules.includes(func.id)) {
                      item.checkedRules.push(func.id)
                      let index = this.selectedRules.findIndex(ruleId => ruleId == func.id)
                      this.selectedRules.splice(index, 1)
                    }
                  })
                }
              }
            }
          })
        }

        // 后台旧数据兼容处理
        this.spinning = false
      }).catch(err => {
        this.spinning = false
      })
    },
    del(roleId) {
      this.api.delRole({ id: roleId }).then(res => {
        this.$message.success(res.data.msg)
        this.getRoleList()
      })
    },
    // 启用 / 停用
    switchStatus(isChecked, rowId) {
      let _self = this
      let msg = isChecked ? '您确认要启用该角色吗？' : '您确认要停用该角色吗？'
      this.$confirm({
        title: '友情提示',
        content: msg,
        onOk() {
          return new Promise((resolve, reject) => {
            _self.api.switchRoleStatus({
              id: rowId,
              status: isChecked ? 1 : 0
            }).then(res => {
              _self.$message.success(res.data.msg)
              _self.getRoleList()
              resolve()
            }).catch(() => {
              reject()
            })
          })
        }
      })
    },
    /* 弹框table交互逻辑 */
    onSelect(record, selected, rows, event) {
      if (selected) {
        console.log(record);
        /**
         * 当勾选时，父子联动处理
         * 1.循环菜单数组，若带有sub字段，则为导航菜单。将导航菜单下的所有子页面勾选
         * 2.导航菜单设置selectedChild，用于存储所勾选的子页面数据
         */
        if (record.sub) {
          record.sub.forEach(item => {
            // 判断是否有该id
            if (!this.selectedRules.includes(item.id)) {
              this.selectedRules.push(item.id)
              record.selectedChild.push(item.id)
            }
            item.function_list.forEach(func => {
              if (!item.checkedRules.includes(func.id)) {
                item.checkedRules.push(func.id)
              }
            })
          })
        }
        // 若带有function_list，则判断为页面；勾选子页面时，判断是否勾选父级菜单，若未勾选则勾选；若勾选则不再重复勾选
        // 勾选页面时，需要在父元素的selectedChild里面同步更新勾选状态，
        if (record.function_list) {
          record.function_list.forEach(item => {
            if (!record.checkedRules.includes(item.id)) {
              record.checkedRules.push(item.id)
            }
          })
          if (!this.selectedRules.includes(record.pid)) {
            this.selectedRules.push(record.pid) // 勾选子菜单时，自动勾选父级
          }
          let parent = this.menuList.find(item => item.id == record.pid)
          if (parent && !parent.selectedChild.includes(record.id)) {
            parent.selectedChild.push(record.id)
          }
        }

      } else {
        // 父级取消时，子级联动
        if (record.children) {
          record.children.forEach(item => {
            let index = this.selectedRules.findIndex(rule => rule == item.id)
            this.selectedRules.splice(index, 1)
            item.checkedRules = []
          })
          record.selectedChild = []
        }
        // 页面功能取消
        if (record.function_list) {
          record.checkedRules = []
          let parent = this.menuList.find(item => item.id == record.pid)
          let index = parent.selectedChild.findIndex(item => item.id == record.id)
          parent.selectedChild.splice(index, 1)
          if (parent.selectedChild.length == 0) {
            let parentIndex = this.selectedRules.findIndex(id => id == record.pid)
            this.selectedRules.splice(parentIndex, 1)
          }
        }
      }
    },
    // 选择发生变化
    onSelectChange(keys, rows) {
      this.selectedRules = keys
    },
    // 全选
    onSelectAll(selected) {
      this.menuList.forEach(menu => {
        if (menu.type == 0) { // 导航菜单处理
          menu.children.forEach(child => {
            child.function_list.forEach(item => {
              if (selected) {
                child.checkedRules.push(item.id)
              } else {
                child.checkedRules = []
              }
            })
          })
        } else {
          if (menu.function_list) {
            menu.function_list.forEach(item => {
              if (selected) {
                menu.checkedRules.push(item.id)
              } else {
                menu.checkedRules = []
              }
            })
          }
        }
      })
    },
    // 选功能，联动菜单
    selectFunc(checkedValue, record) {
      if (checkedValue.length != 0) {
        if (!this.selectedRules.includes(record.pid)) {
          this.selectedRules.push(record.pid)
        }
        if (!this.selectedRules.includes(record.id)) {
          this.selectedRules.push(record.id)
        }
      }
    },
    expandChange(expanded, row) {
      if (expanded) {
        this.expandRows.push(row.id)
      } else {
        let index = this.expandRows.findIndex(item => item == row.id)
        this.expandRows.splice(index, 1)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.rule_validate {
  margin-bottom: 0;
  /deep/.ant-form-item-label {
    width: auto;
    text-align: left;
  }
  /deep/.ant-form-explain {
    margin-top: 8px;
  }
}
.ant-modal-body {
  .ant-form {
    /deep/.ant-table-row-level-1 {
      background-color: #f4f7fe;
      td {
        border-color: #fff;
      }
    }
  }
  .checkbox-list {
    padding: 7px 0;
    .list-item {
      padding: 4px 0 0 4px;
      height: 24px;
    }
  }
}
</style>